// Hero.scss
.Hero {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;

  .Hero_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.7);
    z-index: -1;
    animation: scaleBg 20s ease-in-out infinite;
  }

  @keyframes scaleBg {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }

  .Hero_content {
    max-width: 800px;
    padding: 0 20px;

    &_title {
      font-size: 3.5rem;
      line-height: 1.2;
      margin-bottom: 20px;
      text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    &_desc {
      font-size: 1.5rem;
      line-height: 1.5;
      margin-bottom: 40px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }

    &_actions {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;

      a {
        padding: 15px 30px;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 30px;
        text-decoration: none;
        transition: background-color 0.3s, transform 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          transform: translateY(-5px);
        }

        b {
          font-size: 1.2rem;
        }

        span {
          font-size: 0.9rem;
          opacity: 0.8;
        }
      }

      .btn-primary {
        background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
        color: #fff;

        &:hover {
          background: linear-gradient(135deg, #0056b3 0%, #007bff 100%);
        }
      }

      .btn-secondary {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.5);

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .scroll-indicator {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    span {
      display: block;
      width: 25px;
      height: 40px;
      border: 2px solid #fff;
      border-radius: 25px;
      position: relative;
      box-sizing: border-box;

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 50%;
        width: 6px;
        height: 6px;
        background: #fff;
        border-radius: 50%;
        transform: translateX(-50%);
        animation: scroll 2s infinite;
      }
    }

    @keyframes scroll {
      0% {
        opacity: 0;
        transform: translate(-50%, 0);
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translate(-50%, 10px);
      }
    }
  }
}
