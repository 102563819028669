// Navbar.scss
.Navbar {
  &.Navbar_elevated {
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
  }

  .MuiToolbar-root {
    min-height: 80px;
  }

  .Navbar_logo {
    height: 60px;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .nav-buttons {
    display: flex;
    align-items: center;

    button {
      margin-left: 15px;
      text-transform: none;
      font-weight: 500;
      border-radius: 8px;
      padding: 8px 16px;
      transition: background-color 0.3s, color 0.3s;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background-color: rgba(0, 123, 255, 0.1);
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;

      button {
        margin: 10px 0;
      }
    }
  }
}
