// Featured.scss
.Featured {
  padding: 60px 0;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);

  .Featured_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      font-weight: bold;
      font-size: 1.5em;
      margin-bottom: 40px;
      color: #333;
    }

    .logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;

      .logo-item {
        img {
          width: 150px;
          height: auto;
          filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
          transition: transform 0.3s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .Featured_content {
      .logos {
        gap: 20px;

        .logo-item {
          img {
            width: 120px;
          }
        }
      }
    }
  }
}
