@import "../../assets/styles/colors.scss";

// Variables para reutilización y mantenibilidad
$modalPadding: 30px;
$modalBorderRadius: 20px;
$spaceBetweenElements: 20px;
$buttonMargin: 10px;
$dangerColor: red;

.Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  // Overlay para el fondo
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  &_content {
    background-color: $white;
    z-index: 20;
    padding: $modalPadding;
    border-radius: $modalBorderRadius;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    margin: auto;

    &_title {
      margin-bottom: $spaceBetweenElements;
      font-size: 1.5em;
    }

    // Estilos específicos para AssignRoute
    &.AssignRoute_content {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        margin-bottom: $spaceBetweenElements;
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 10px;

        .parcel-details {
          margin-top: 10px;
          font-size: 0.9em;
          color: #555;
        }
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: $modalBorderRadius;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:not(.btn-danger) {
          background-color: #007bff;
          color: white;

          &:hover {
            background-color: darken(#007bff, 10%);
          }
        }

        &.btn-danger {
          background-color: $dangerColor;
          color: white;

          &:hover {
            background-color: darken($dangerColor, 10%);
          }
        }
      }
    }
  }

  // Media queries para responsividad
  @media (max-width: 768px) {
    &_content {
      padding: $modalPadding / 2;
    }
  }
}
