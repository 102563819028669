@import "../../assets//styles/colors.scss";

.Dashboard {
  &_content {
    margin-top: 1rem;
    width: 100%;
    overflow-x: auto;

    @media all and (min-width: 768px) {
      margin-top: 1rem;
    }

    .tab-menu {
      display: flex;
      justify-content: start;
      margin-bottom: 20px;
    
      button {
        padding: 10px 20px;
        cursor: pointer;
        background: none;
        border: none;
        color: #555;
    
        &.active {
          border-bottom: 2px solid #007bff;
          color: #007bff;
        }
      }
    }

    &_table {
      width: 100%;
      text-align: left;
      font-size: 0.8rem;
      box-shadow: 0 0 5px 0 #ddd;
      border-radius: 5px;
      overflow: hidden;

      @media all and (min-width: 768px) {
        font-size: 1rem;
      }

      thead {
        background-color: $grey_light;
        
        th {
          padding: 0.6rem 1rem;
          text-transform: uppercase;
          color: $grey_dark;

          @media all and (min-width: 768px) {
            padding: 1rem;
          }
        }
      }

      tbody {
        td {
          border-bottom: 1px solid #ddd;
          padding: 0.6rem 1rem;

          @media all and (min-width: 768px) {
            padding: 1rem;
          }
        }
      }

      &_request-details {
        span {
          color: $primary;
          font-weight: 500;
          margin-right: 0.3rem;
        }
      }

      &_request-status {
        span {
          padding: 0.3rem 0.6rem;
          border-radius: 0.6rem;
          font-weight: 500;
        }

        &_waiting {
          span {
            background-color: $secondary;
          }
        }

        &_intransit {
          span {
            background-color: $primary;
            color: $white;
          }
        }

        &_delivered {
          span {
            background-color: $green_light;
          }
        }
      }
    }

    &_empty {
      padding: 1rem;
      text-align: center;
      box-shadow: 0 0 5px 0 #ddd;
      border-radius: 5px;

      @media all and (min-width: 768px) {
        padding: 2rem;
      }
    }
  }
}
