// Register.scss
@import '../../assets/styles/colors.scss';

// Define $primary-color if it's not in colors.scss
$primary-color: #3498db !default; // Replace #3498db with your desired color if needed

.Register {
  overflow: hidden;
  min-height: 100vh;

  &_content {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &_left {
      flex: 1;
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: brightness(0.9);
        transition: transform 5s ease;
      }

      &:hover img {
        transform: scale(1.1);
      }

      @media (max-width: 960px) {
        display: none;
      }
    }

    &_right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      @media (max-width: 960px) {
        width: 100%;
        padding: 20px;
      }

      &_content {
        width: 100%;
        max-width: 500px;

        &_subtitle {
          font-weight: 600;
          margin-bottom: 30px;
          font-size: 1.8rem;
          color: $primary-color;
          text-align: center;
        }

        &_form {
          display: flex;
          flex-direction: column;

          .MuiFormControl-root {
            margin-bottom: 20px;
          }

          .warning-message {
            background-color: rgba(255, 165, 0, 0.1);
            padding: 10px;
            border-left: 4px solid orange;
            margin-top: 10px;
            font-size: 0.9rem;
          }

          .button-container {
            margin-top: 20px;

            .submit-button {
              padding: 12px 20px;
              font-size: 1rem;
              text-transform: none;
              font-weight: 600;
              border-radius: 8px;
            }
          }
        }

        &_underForm {
          margin-top: 20px;
          text-align: center;

          &_actions {
            margin-top: 20px;
            font-size: 0.9rem;

            span {
              color: $primary-color;
              font-weight: bold;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
